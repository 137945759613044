interface Translation {
  [key: string]: {
    [key: string]: string;
  };
}

export const TRANSLATIONS: Translation = {
  "Filter by industry": {},
  Apply: {},
  "All Industries": {},
  "Hello!": {
    "zh-CN": "你好!",
  },
  "Sign In": {
    "zh-CN": "登录",
  },
  "Get Started": {
    "zh-CN": "开启您的Flexport旅程",
  },
  "Learn More": {
    "zh-CN": "了解更多",
  },
  "Load More": {
    "zh-CN": "访问更多",
  },
  "Why Flexport": {
    "zh-CN": "为何选择 Flexport",
  },
  "Our Vision": {
    "zh-CN": "我们的愿景",
  },
  "Our Tech Platform": {
    "zh-CN": "我们的科技平台",
  },
  "Our Service Model": {
    "zh-CN": "我们的服务模式",
  },
  "Logistics Services": {
    "zh-CN": "物流服务",
  },
  "Ocean Shipping": {
    "zh-CN": "海运",
  },
  "FCL Shipping": {
    "zh-CN": "整柜海运",
  },
  "LCL Shipping": {
    "zh-CN": "散货海运",
  },
  "Air Freight": {
    "zh-CN": "空运",
  },
  "Ground Transportation": {
    "zh-CN": "陆运",
  },
  "Supply Chain Services": {
    "zh-CN": "供应链服务",
  },
  "Customs Brokerage": {
    "zh-CN": "清关咨询服务",
  },
  "Trade Finance": {
    "zh-CN": "贸易金融",
  },
  "Carbon Offset": {
    "zh-CN": "碳抵消",
  },
  "Duty Drawback": {
    "zh-CN": "退税服务",
  },
  "Order Management": {
    "zh-CN": "订单管理",
  },
  Resources: {
    "zh-CN": "资源",
  },
  Blog: {
    "zh-CN": "博客",
  },
  "Market Updates": {
    "zh-CN": "市场行情动态",
  },
  "Videos and Webinars": {
    "zh-CN": "视频和在线分享会",
  },
  "Help Center": {
    "zh-CN": "帮助中心",
  },
  Company: {
    "zh-CN": "公司介绍",
  },
  "Our Story": {
    "zh-CN": "我们的故事",
  },
  Press: {
    "zh-CN": "媒体资源",
  },
  Careers: {
    "zh-CN": "加入我们",
  },
  Engineering: {
    "zh-CN": "软件开发",
  },
  Contact: {
    "zh-CN": "联系我们",
  },
  Services: {
    "zh-CN": "服务",
  },
  "Latest & Greatest": {
    "zh-CN": "最新资讯",
  },
  "New Dashboard Updates": {
    "zh-CN": "",
  },
  Customers: {
    "zh-CN": "客户",
  },
  Leadership: {
    "zh-CN": "领导团队",
  },
  "Read More": {
    "zh-CN": "阅读更多内容",
  },
  "Ready to Get Started?": {
    "zh-CN": "准备好开始了吗？",
  },
  "Sign up for a Flexport account or ask to see our platform in action.": {
    "zh-CN": "注册 Flexport 账号或要求查看我们的行动平台。",
  },
  "Watch Now": {
    "zh-CN": "",
  },
  "Videos & Webinars": {
    "zh-CN": "",
  },
  Webinars: {
    "zh-CN": "线上讲座",
  },
  Webinar: {
    "zh-CN": "线上讲座",
  },
  Video: {
    "zh-CN": "视频",
  },
  FAQs: {
    "zh-CN": "",
  },
  "Share the Article": {
    "zh-CN": "",
  },
  "More From Flexport": {
    "zh-CN": "更多",
  },
  "White Papers": {
    "zh-CN": "白皮书",
  },
  "E-Guides": {
    "zh-CN": "电子书",
  },
  Offices: {
    "zh-CN": "",
  },
  "Explore Our Teams": {
    "zh-CN": "",
  },
  "View All Articles": {
    "zh-CN": "",
  },
  "For media inquires, please contact us at": {
    "zh-CN": "",
  },
  "For other inquiries, please contact us at": {
    "zh-CN": "",
  },
  Download: {
    "zh-CN": "",
  },
  "In the News": {
    "zh-CN": "",
  },
  "Press Resources": {
    "zh-CN": "",
  },
  "Find Out More": {
    "zh-CN": "更多信息",
  },
  "Upcoming Events": {
    "zh-CN": "最新活动",
  },
  "Enter your business email": {
    "zh-CN": "输入您的公司电子邮件地址",
  },
  "Sign Up For Flexport": {
    "zh-CN": "Flexport 帐号注册",
  },
  "Sign up for Flexport": {
    "zh-CN": "Flexport 帐号注册",
  },
  "Sign Up With Flexport": {
    "zh-CN": "Flexport 帐号注册",
  },
  "First Name": {
    "zh-CN": "名字",
  },
  "Last Name": {
    "zh-CN": "姓氏",
  },
  "Company Name": {
    "zh-CN": "公司名称",
  },
  "E-mail": {
    "zh-CN": "邮箱地址",
  },
  "I agree to the": {
    "zh-CN": "我同意",
  },
  "Terms of Service": {
    "zh-CN": "服务条款",
  },
  "Privacy Policy": {
    "zh-CN": "隐私政策",
  },
  "Privacy Policy Footer DE": {
    "zh-CN": "",
  },
  Imprint: {
    "zh-CN": "",
  },
  "Opt Out of Cookies": {
    "zh-CN": "",
  },
  and: {
    "zh-CN": "和",
  },
  "Business Phone": {
    "zh-CN": "电话号码",
  },
  "Phone Number": {
    "zh-CN": "电话号码",
  },
  "Work Email": {
    "zh-CN": "邮箱地址",
  },
  "Read Our Freight Market Updates": {
    "zh-CN": "阅读航运市场动态",
  },
  "Get weekly insights into all things freight, delivered right to your WeChat.":
    {
      "zh-CN": "关注微信公众号阅读每周的航运市场动态。",
    },
  "Scan the QR code to follow Flexport on WeChat now.": {
    "zh-CN": "立即扫描二维码关注 Flexport。",
  },
  "Track and Trace": {
    "zh-CN": "货物跟踪",
  },
  "Trade Services": {
    "zh-CN": "贸易服务",
  },
  "Trade Advisory": {
    "zh-CN": "贸易咨询",
  },
  "Financial Services": {
    "zh-CN": "金融服务",
  },
  Insurance: {
    "zh-CN": "货物保险",
  },
  APIs: {
    "zh-CN": "API 应用程序接口",
  },
  Glossary: {
    "zh-CN": "行业术语",
  },
  Media: {
    "zh-CN": "媒体报道",
  },
  Events: {
    "zh-CN": "最新活动",
  },
  "Featured Blog Posts": {
    "zh-CN": "最新博客文章",
  },
  "Ocean, Air, Ground, All From the Cloud": {
    "zh-CN": "在云端管理您的海、陆、空运",
  },
  "Expert Teams Dedicated to Your Business": {
    "zh-CN": "专注为您服务的专家团队",
  },
  LEGAL: {
    "zh-CN": "法律声明",
  },
  "Copyright © 2024 Flexport Inc.": {
    "zh-CN": "© 2024 Flexport, Inc. 版权所有",
  },
  "Customs brokerage services are provided by Flexport’s wholly-owned subsidiary, Flexport Customs LLC, a licensed customs brokerage with a national permit. International ocean freight forwarding services are provided by Flexport International LLC, a licensed Ocean Transportation Intermediary FMC# 025219NF. U.S. trucking services are provided by Flexport International, LLC, a FMCSA licensed property broker USDOT #2594279 and MC #906604-B. Cargo insurance is underwritten by an authorized insurance company and offered by Flexport affiliates. Insurance coverage is not available in all jurisdictions. See http://flx.to/insurance-notice for more cargo insurance information and disclosures. All transactions are subject to Flexport’s standard terms and conditions, available at www.flexport.com/terms":
    {
      "zh-CN":
        "美国清关服务由 Flexport 全资子公司且为美国持牌报关行的 Flexport Customs LLC 提供。国际海运代理服务由具有无船承运人资质 (FMC＃025219NF) 的 Flexport International LLC 提供。美国内陆拖车服务由具有 FMCSA 代理牌照 (USDOT #2594279 和 MC #906604-B) 的 Flexport International LLC 提供。所有交易均适用Flexport 之标准条款 (详情请访问 cn.flexport.com/terms)。",
    },
  "Terms of Use": {
    "zh-CN": "使用条款",
  },
  "COVID-19 Trade Insights": {
    "zh-CN": "新型冠状病毒贸易见解",
  },
  Updates: {
    "zh-CN": "最新消息",
  },
  "Thought Leadership": {
    "zh-CN": "思想领导",
  },
  News: {
    "zh-CN": "媒体报道",
  },
  "By submitting this form, you agree that we may contact you using the information you provided in this form about our products and services. Your data will be used subject to Flexport’s Privacy Policy. You can unsubscribe at any time using the link in our emails.":
    {
      "zh-CN":
        "通过提交此表格，您同意我们使用您提供的数据与您联系，提供与您的请求/提交相关的 Flexport 飞协博产品和服务信息，包括教育资源和产品更新。我们将遵守 Flexport 飞协博的隐私政策使用您的数据。您可以在任何时候通过我们电子邮件底部提供的链接选择取消订阅。",
    },
  "I would like to receive additional communications from Flexport, including educational sources, product updates, and outreach from representatives. I understand that I may opt-out at any time.":
    {
      "zh-CN":
        "我想从Flexport那里获得更多的资訊，包括教育资料，产品更新和销售推广。我了解我可以随时取消订阅",
    },
  "Back to Blog": {
    "zh-CN": "",
  },
  "Featured Videos and Webinars": {
    "zh-CN": "",
  },
  "Sign Up for Freight Market Updates": {
    "zh-CN": "",
  },
  "I agree to the storing and processing of my personal data by Flexport as described in the Terms of Service and Privacy Policy.":
    {
      "zh-CN": "",
    },
  "Get weekly insights into all things freight, delivered right to your inbox.":
    {
      "zh-CN": "",
    },
  "Thanks for Subscribing!": {
    "zh-CN": "",
  },
  "You are now subscribed to Freight Market Updates.": {
    "zh-CN": "",
  },
  "Enter Your Work Email": {
    "zh-CN": "输入您的公司电子邮件地址",
  },
  "Invalid email": {
    "zh-CN": "",
  },
  "Sign up here for original pieces on global trade and logistics.": {
    "zh-CN": "",
  },
  "You are now subscribed to the Flexport Blog.": {
    "zh-CN": "",
  },
  "Flexport makes shipping your cargo transparent, reliable, and affordable": {
    "zh-CN": "",
  },
  "Dangerous Goods": {
    "zh-CN": "危险品",
  },
  Continue: {
    "zh-CN": "继续",
  },
  Finish: {
    "zh-CN": "完成",
  },
  "Thank You!": {
    "zh-CN": "感谢您！",
  },
  Required: {
    "zh-CN": "必填",
  },
  "Please enter an email address": {
    "zh-CN": "请输入邮箱地址",
  },
  "Please enter a valid email address": {
    "zh-CN": "请输入有效的邮箱地址",
  },
  "Please enter a valid phone number": {
    "zh-CN": "请输入有效的电话号码",
  },
  "Please enter a valid response": {
    "zh-CN": "Please enter a valid response",
  },
  Country: {
    "zh-CN": "国家",
  },
  "Select an option": {
    "zh-CN": "请选择",
  },
  "An expert will get back to you very soon.": {
    "zh-CN": "我们会尽快与您联系。",
  },
  "Back to Flexport.com": {
    "zh-CN": "回到 Flexport.com",
  },
  "Back to flexport.com": {
    "zh-CN": "回到 Flexport.com",
  },
  "Less than 25": {
    "zh-CN": "低于 25",
  },
  "None - I'm a first time shipper": {
    "zh-CN": "我首次使用物流服务",
  },
  "None - I don't ship freight": {
    "zh-CN": "不适用，我没有物流需求",
  },
  "I'm a logistics provider": {
    "zh-CN": "不适用，我是物流服务商",
  },
  "International Commercial Terms": {
    "zh-CN": "您最常用的贸易条款",
  },
  "EXW or FOB": {
    "zh-CN": "EXW 或 FOB",
  },
  "CIF, DAP, DDU or DDP": {
    "zh-CN": "CIF、DAP、DDU 或 DDP",
  },
  Other: {
    "zh-CN": "其他",
  },
  China: {
    "zh-CN": "中国大陆",
  },
  Macao: {
    "zh-CN": "中国澳门",
  },
  Taiwan: {
    "zh-CN": "中国台湾",
  },
  "Annual International Freight Shipments": {
    "zh-CN": "年度全球货运量",
  },
  "How We Hire": {
    "zh-CN": "我们如何招聘",
  },
  "More About Flexport": {
    "zh-CN": "",
  },
  "Follow Us on LinkedIn": {
    "zh-CN": "",
  },
  "View Us on Glassdoor": {
    "zh-CN": "",
  },
  "View All Open Positions": {
    "zh-CN": "",
  },
  "About this author": {
    "zh-CN": "",
  },
  "Get Free Weekly Supply Chain News Updates": {
    "zh-CN": "",
  },
  "Subscribe for the latest news on trade lanes, customs and tariff changes, and expert economic insight.":
    {
      "zh-CN": "",
    },
  Developers: {
    "zh-CN": "开发工程师",
  },
  "Job Title": {
    "zh-CN": "职称",
  },
  "Contact Us": {
    "zh-CN": "联系我们",
  },
  "Send us a message": {
    "zh-CN": "给我们发送信息",
  },
  Email: {
    "zh-CN": "电子邮件",
  },
  Phone: {
    "zh-CN": "联络电话",
  },
  Message: {
    "zh-CN": "信息",
  },
  General: {
    "zh-CN": "总部",
  },
  Online: {
    "zh-CN": "电邮",
  },
  "Global Offices": {
    "zh-CN": "全球办事处",
  },
  Send: {
    "zh-CN": "提交",
  },
  "Sorry, but we are unable to process your message": {
    "zh-CN": "很抱歉，我们此时无法处理您的信息",
  },
  "Thank you for your message. We will get back to you as soon as we can.": {
    "zh-CN": "感谢您的留言，我们会尽快与您联系。",
  },
  step: {
    "zh-CN": "step",
  },
  "Form progress status": {
    "zh-CN": "Form progress status",
  },
  completed: {
    "zh-CN": "completed",
  },
  "Country/Region": {
    "zh-CN": "国家/地区",
  },
  "Country code": {
    "zh-CN": "国家地区代码",
  },
  "Select country": {
    "zh-CN": "Select country",
  },
  "Something went wrong. Try again.": {
    "zh-CN": "Something went wrong. Try again.",
  },
  "That's taken care of.": {
    "zh-CN": "That's taken care of.",
  },
  "Enter your email": {
    "zh-CN": "Enter your email",
  },
  Search: {
    "zh-CN": "Search",
  },
  "Hello! What can we help you find?": {
    "zh-CN": "Hello! What can we help you find?",
  },
  "Visit us on": {
    "zh-CN": "Visit us on",
  },
  "Skip to content": {
    "zh-CN": "Skip to content",
  },
  "Share Page": {
    "zh-CN": "",
  },
  "Share Page via Email": {
    "zh-CN": "",
  },
  "Share Page via Twitter": {
    "zh-CN": "",
  },
  "Share Page via LinkedIn": {
    "zh-CN": "",
  },
  "Share Page via Facebook": {
    "zh-CN": "",
  },
  "Copy Page Link": {
    "zh-CN": "",
  },
  Breadcrumbs: {
    "zh-CN": "",
  },
  "close drawer menu": {
    "zh-CN": "close drawer menu",
  },
  "Ready to Apply?": {
    "zh-CN": "准备好加入我们了吗",
  },
  "Select a team": {
    "zh-CN": "选择团队",
  },
  "Select a location": {
    "zh-CN": "选择地点",
  },
  "See Open Roles": {
    "zh-CN": "查看空缺职位",
  },
  "Clear Filters": {
    "zh-CN": "取消筛选",
  },
  "Your Next Role Awaits": {
    "zh-CN": "机会正等着您呢",
  },
  "View Openings": {
    "zh-CN": "查看空缺职位",
  },
  "Account Solutions & Implementation": {
    "zh-CN": "客户解决方案与实施",
  },
  "Business Enablement": {
    "zh-CN": "业务支持",
  },
  "Business Operations": {
    "zh-CN": "业务运营",
  },
  "Client Solutions": {
    "zh-CN": "客户解决方案",
  },
  Customs: {
    "zh-CN": "海关",
  },
  "Data Science & Analytics": {
    "zh-CN": "数据科学与分析",
  },
  Finance: {
    "zh-CN": "财务",
  },
  "Flexport Ecosystem ": {
    "zh-CN": "Flexport 飞协博生态系统",
  },
  "Flexport.org": {
    "zh-CN": "",
  },
  "General Management": {
    "zh-CN": "管理",
  },
  "Global Brand": {
    "zh-CN": "全球品牌",
  },
  "IT, Security & Infrastructure": {
    "zh-CN": "IT、安全与基础设施",
  },
  Legal: {
    "zh-CN": "法务",
  },
  Marketing: {
    "zh-CN": "营销",
  },
  "Office of CEO": {
    "zh-CN": "首席执行官办公室",
  },
  "Office of CTO": {
    "zh-CN": "首席技术官办公室",
  },
  "Operational Excellence": {
    "zh-CN": "卓越运营",
  },
  Partnerships: {
    "zh-CN": "合作关系",
  },
  People: {
    "zh-CN": "人力资源",
  },
  "Product Management": {
    "zh-CN": "产品管理",
  },
  Sales: {
    "zh-CN": "销售",
  },
  "Supply Chain Operations": {
    "zh-CN": "供应链运营",
  },
  "Technical Program Management": {
    "zh-CN": "技术计划管理",
  },
  "Trade & Financial Services": {
    "zh-CN": "贸易和财务服务",
  },
  "Trade Lane Management, Fulfillment & Procurement": {
    "zh-CN": "贸易航线管理",
  },
  Trucking: {
    "zh-CN": "卡车运输",
  },
  "User Experience": {
    "zh-CN": "用户体验",
  },
  Warehousing: {
    "zh-CN": "仓储",
  },
  "Workplace & Facilities": {
    "zh-CN": "办公室和设施行政",
  },
  Amsterdam: {
    "zh-CN": "阿姆斯特丹",
  },
  Atlanta: {
    "zh-CN": "亚特兰大",
  },
  Belgium: {
    "zh-CN": "比利时",
  },
  Bellevue: {
    "zh-CN": "贝尔维尤",
  },
  Chicago: {
    "zh-CN": "芝加哥",
  },
  Cologne: {
    "zh-CN": "科隆",
  },
  Copenhagen: {
    "zh-CN": "哥本哈根",
  },
  Dallas: {
    "zh-CN": "达拉斯",
  },
  Frankfurt: {
    "zh-CN": "法兰克福",
  },
  Hamburg: {
    "zh-CN": "汉堡",
  },
  "Ho Chi Minh City": {
    "zh-CN": "胡志明市",
  },
  "Hong Kong": {
    "zh-CN": "中国香港",
  },
  London: {
    "zh-CN": "伦敦",
  },
  "Los Angeles": {
    "zh-CN": "洛杉矶",
  },
  Miami: {
    "zh-CN": "迈阿密",
  },
  Munich: {
    "zh-CN": "慕尼黑",
  },
  "New York City": {
    "zh-CN": "纽约市",
  },
  Phoenix: {
    "zh-CN": "凤凰城",
  },
  Plano: {
    "zh-CN": "普拉诺",
  },
  Portland: {
    "zh-CN": "波特兰",
  },
  "San Francisco": {
    "zh-CN": "三藩市",
  },
  Seoul: {
    "zh-CN": "首尔",
  },
  Shanghai: {
    "zh-CN": "上海",
  },
  Shenzhen: {
    "zh-CN": "深圳",
  },
  Singapore: {
    "zh-CN": "新加坡",
  },
  Stuttgart: {
    "zh-CN": "斯图加特",
  },
  Sweden: {
    "zh-CN": "瑞典",
  },
  Taipei: {
    "zh-CN": "台北",
  },
  Toronto: {
    "zh-CN": "多伦多",
  },
  "U.S. Remote": {
    "zh-CN": "在美远程工作",
  },
  "Close search panel": {
    "zh-CN": "Close search panel",
  },
  "Show search panel": {
    "zh-CN": "Show search panel",
  },
  "Search panel showed": {
    "zh-CN": "Search panel showed",
  },
  "Search panel closed": {
    "zh-CN": "Search panel closed",
  },
  "Open blog menu": {
    "zh-CN": "Open blog menu",
  },
  "Close blog menu": {
    "zh-CN": "Close blog menu",
  },
  Overview: {
    "zh-CN": "概况",
  },
  Teams: {
    "zh-CN": "团队",
  },
  Locations: {
    "zh-CN": "地点",
  },
  "Diversity & Inclusion": {
    "zh-CN": "多样性和包容性",
  },
  "Find Your Role at Flexport": {
    "zh-CN": "在 Flexport 飞协博寻找机会",
  },
  Department: {
    "zh-CN": "团队",
  },
  Location: {
    "zh-CN": "地点",
  },
  Openings: {
    "zh-CN": "空缺职位",
  },
  "Play Video": {
    "zh-CN": "Play Video",
  },
  Day: {
    "zh-CN": "Day",
  },
  Month: {
    "zh-CN": "Month",
  },
  Year: {
    "zh-CN": "Year",
  },
  "No results found": {
    "zh-CN": "No results found",
  },
  Origin: {
    "zh-CN": "Origin",
  },
  Destination: {
    "zh-CN": "Destination",
  },
  Cargo: {
    "zh-CN": "Cargo",
  },
  "Freight Quote": {
    "zh-CN": "Freight Quote",
  },
  "Where are you shipping from?": {
    "zh-CN": "Where are you shipping from?",
  },
  "Where are you shipping to?": {
    "zh-CN": "Where are you shipping to?",
  },
  "Ocean - Full container load (FCL)": {
    "zh-CN": "Ocean - Full container load (FCL)",
  },
  "Ocean - Less container load (LCL)": {
    "zh-CN": "Ocean - Less container load (LCL)",
  },
  Air: {
    "zh-CN": "Air",
  },
  "20' Standard": {
    "zh-CN": "20' Standard",
  },
  "40' Standard": {
    "zh-CN": "40' Standard",
  },
  "40' High Cube": {
    "zh-CN": "40' High Cube",
  },
  "45' High Cube": {
    "zh-CN": "45' High Cube",
  },
  Batteries: {
    "zh-CN": "Batteries",
  },
  "Hazardous Materials": {
    "zh-CN": "Hazardous Materials",
  },
  "Creams, liquids, powders": {
    "zh-CN": "Creams, liquids, powders",
  },
  Magnets: {
    "zh-CN": "Magnets",
  },
  "Weight (in KG)": {
    "zh-CN": "Weight (in KG)",
  },
  "Volume (in CBM)": {
    "zh-CN": "Volume (in CBM)",
  },
  "Estimated cargo value (in USD)": {
    "zh-CN": "Estimated cargo value (in USD)",
  },
  "Do any of your products contain the following goods?": {
    "zh-CN": "Do any of your products contain the following goods?",
  },
  Save: {
    "zh-CN": "Save",
  },
  Update: {
    "zh-CN": "Update",
  },
  Cancel: {
    "zh-CN": "Cancel",
  },
  Edit: {
    "zh-CN": "Edit",
  },
  "Shipment Information": {
    "zh-CN": "Shipment Information",
  },
  "Cargo Ready Date": {
    "zh-CN": "Cargo Ready Date",
  },
  "Est. Transit Time": {
    "zh-CN": "Est. Transit Time",
  },
  "Port to Port": {
    "zh-CN": "Port to Port",
  },
  "Port to Door": {
    "zh-CN": "Port to Door",
  },
  Submit: {},
  Subscribe: {},
  "This site is protected by reCAPTCHA Enterprise and the Google": {
    "zh-CN": "本网站已获 reCAPTCHA 和 Google 的隐私政策和服务条款保护。",
  },
  "as well as the": {
    "zh-CN": "",
  },
  "recaptcha-privacy-policy-de": {
    "zh-CN": "",
  },
  "recaptcha-term-by-google": {
    "zh-CN": "",
  },
  "Shanghai ICP No. 16041494-1": {
    "zh-CN": "沪ICP备16041494号-1",
  },
};
