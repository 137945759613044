import sanitizeHtml from "sanitize-html";
import {SANITIZATION_OPTIONS, LOCALE_TO_DOMAIN} from "../config";
import idx from "idx";
import translate from "./translate";
import {MaybeType} from "../types";

export const AMPLITUDE_ACTIVE_EXPERIMENTS: Array<string> = [];

/**
 * helper method to set inert attribute of content
 * @param {boolean} inert
 */
export const inertContent = (inert: boolean) => {
  const $content = document.querySelectorAll(".content-inertable");

  if ($content) {
    for (const elem of $content) {
      (elem as HTMLElement).inert = inert;
    }
  }
};

/**
 * helper method to generate content slug root path based on type
 * @param {string?} articleType
 * @return {string}
 */
export const getContentRootPathBasedOnArticleType = (articleType?: string) => {
  switch (articleType) {
    case "blog":
      return "/blog";
    case "help center":
    case "Help Article":
    case "Help Center Article":
      return "/help";
    case "Glossary Term":
    case "glossary":
      return "/glossary";
    case "capital":
    case "capital + blog":
      // GROW-3195 if type capital
      // parent path should be products/capital
      return "/products/capital";
    case "research + blog":
    case "research":
      // GROW-3146, if type research + blog
      // parent path should be research
      return "/research";
    case "Market Update":
      return "/market-updates";
    case "case-study":
      return "/company/customers";
    case "company/newsroom":
      return "/";
    case "events":
      return "/events";
    default:
      return "/";
  }
};

/**
 * helper method to construct any article/template page url
 * @param {?string} slug
 * @param {string} type
 * @return {string}
 */
export const constructArticleTemplatePageURL = (
  slug?: MaybeType<string>,
  type: string = "blog"
): string => {
  const temp = getContentRootPathBasedOnArticleType(type || "blog");
  return slug != null ? `${temp}/${slug}` : `${temp}`;
};

/**
 * helper method to construct back button label
 * @param {string} type
 * @return {string}
 */
export const getArticleBackBtnLabel = (type: string): string => {
  let goBackText = translate("Back to Blog");
  switch (type) {
    case "research + blog":
      // GROW-3146, if type research + blog
      // parent path should be research
      goBackText = translate("Back to Research");
      break;
    // GROW-3195 if type capital
    // parent path should be products/capital
    case "capital":
    case "capital + blog":
      // GROW-3169, if type capital + blog
      // parent path should be products/capital
      goBackText = translate("Back to Capital");
      break;
    case "webinars":
      goBackText = translate("Back to Videos and Webinars");
      break;
    case "Glossary Term":
      goBackText = translate("Back to Glossary");
      break;
    case "Market Update":
      goBackText = translate("Back to Market Updates");
      break;
    case "Help Center Article":
    case "Help Center":
      goBackText = translate("Back to Help Center");
      break;
    case "case-study":
      goBackText = translate("Back to Customers");
      break;
    default:
      goBackText = translate(
        `Back to ${
          type !== null && type !== undefined ? toSentenceCase(type) : "Blog"
        }`
      );
      break;
  }

  return goBackText;
};

/**
 * helper method to filter null contents from the gwl nodes object
 * @param {{edges: {node: Record<string, any>}}} nodes
 * @return {Array<any>}
 */
export const narrowConnection = (nodes: Record<string, any>): Array<any> => {
  const edges: any = idx(nodes, _ => _.edges) || [];
  return edges
    .filter(Boolean)
    .map((e: any) => e.node)
    .filter(Boolean);
};

/**
 * util method to updateActiveExperiment, use in a/b testing
 * in layout component
 * @param {string} experimentName
 * @param {string} variant
 */
export const updateActiveExperiments = (
  experimentName: string,
  variant: string
) => {
  const experiment = `${experimentName}: ${variant}`;

  // experiment: variant names should be unique
  if (
    experimentName &&
    variant &&
    !AMPLITUDE_ACTIVE_EXPERIMENTS.includes(experiment)
  ) {
    AMPLITUDE_ACTIVE_EXPERIMENTS.push(experiment);
  }
};

/**
 * util method to generate static key value instead of using uuid
 * using uuid will cause re-rendering on every single state update
 * @param {string} str
 * @return {string}
 */
export const generateKeyId = (str: string): string => {
  return encodeURIComponent(str);
};

/**
 * Join a bunch of CSS classes into a single string.
 * @return {string}
 */
export function css(...args: string[]): string {
  return args
    .join(" ")
    .replace(/\s+/g, " ") // remove whitespace between classes
    .trim(); // remove whitespace at start and end of string
}

/**
 * helper method to extract query string params value
 * @return {URLSearchParams}
 */
export function getQueryStringParams(): URLSearchParams {
  if (!window || !window.location) {
    throw new Error("getQueryStringParams cannot be invoked in pre-render.");
  }

  const queryString = window.location.search;

  return new URLSearchParams(queryString);
}

/**
 * Convert regular string into lowercase and kebab style.
 * Example: "Lorem ipsum 1234 foo!" => "lorem-ipsum-1234-foo"
 * Taken from:
 * https://gist.github.com/codeguy/6684588
 *
 * @param {(string | number)[]} args
 * @return {string}
 */
export function slugify(...args: (string | number)[]): string {
  return args
    .join(" ")
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim() // remove whitespace at start and end of string
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, "-"); // separator
}

/**
 * Sanitize markup using sanitize-html.
 * Here we manually allow and disallow attributes on elements.
 * @param {string} content
 * @return {string}
 */
export function sanitizeMarkdown(content: string): string {
  const {
    allowedAttributes,
    allowedTags,
    allowedIframeHostnames,
    allowedIframeDomains,
  } = SANITIZATION_OPTIONS;

  return sanitizeHtml(content, {
    allowedTags,
    allowedAttributes,
    allowedIframeHostnames,
    allowedIframeDomains,
  });
}

/**
 * Checks whether a URL points to another domain or a page within our domain.
 * As long as the string begins with '/', it'll be considered internal.
 * Example:
 * 1) /foo/bar => true
 * 2) https://flexport.com => false
 *
 * @param {string} url
 * @return {boolean}
 */
export function isInternalURL(url: string): boolean {
  return /^\/(?!\/)/.test(url);
}

/**
 * internal method to generate url with trailing slash
 *
 * We always add trailing slash to all urls in static site,
 * so people don't need to merge urls on amplitude.
 * Ex. /sign-up vs /sign-up/
 * @param {string} url
 * @return {string}
 */
export function generateURLWithTrailingSlash(url: string) {
  const [pathname, queryString] = url.split("?");
  const pathWithSlash: string =
    pathname === "/" ? pathname : pathname.replace(/\/$|$/, "/");
  return pathWithSlash + (queryString != null ? `?${queryString}` : "");
}

/**
 * Convert a string to camel case.
 * Example: "FooBar" => "fooBar"
 *
 * @param {string} str
 * @return {string}
 */
export function toCamelCase(str: string): string {
  return `${str.charAt(0).toLowerCase()}${str.substring(1)}`;
}

/**
 * Convert a string to sentence case.
 * Example: "foobar" => "Foobar"
 *
 * @param {string} str
 * @return {string}
 */
export function toSentenceCase(str: string): string {
  return `${str.charAt(0).toUpperCase()}${str.substring(1)}`;
}

/**
 * Generates page route based on slug and Contentful type.
 * For example, a TutorialPage entry with the slug "foobar" should result in
 * "/tutorials/foobar/".
 *
 * @param {?string} slug
 * @param {?string} typename
 *
 * @return {string}
 */
export function buildPageRoute(slug?: string, typename?: string): string {
  if (typename === "ContentfulHomePage") {
    return "/";
  }

  if (slug === undefined || slug === null) {
    return "";
  }

  switch (typename) {
    case "tutorialPage":
    case "ContentfulTutorialPage":
      return `/tutorials/${slug}/`;

    case "faqPage":
    case "ContentfulFaqPage":
      return `/faq/${slug}/`;

    default:
      return `/${slug}/`;
  }
}

/**
 * Reinstate scrolling and remove right padding.
 */
export function restoreWindowScroll(): void {
  if (typeof document === "undefined" || document.documentElement == null) {
    return;
  }

  document.documentElement.style.overflow = "";
  document.documentElement.style.paddingRight = "";
  document.documentElement.style.touchAction = "";
}

/**
 * Hide the scrollbar and add right padding to avoid a visual "jump" in content.
 */
export function hideWindowScroll(): void {
  if (
    typeof window === "undefined" ||
    typeof document === "undefined" ||
    document.documentElement == null ||
    document.body == null
  ) {
    return;
  }

  const scrollTrackWidth = window.innerWidth - document.body.offsetWidth;

  document.documentElement.style.overflow = "hidden";
  document.documentElement.style.touchAction = "none";

  if (scrollTrackWidth > 0) {
    document.documentElement.style.paddingRight = `${scrollTrackWidth}px`;
  }
}

/**
 * Check whether a nav link should be set to active based on the current route.
 * This is especially applicable to pages nested under a slug, like tutorials.
 * Example: if the current page is '/tutorial/foobar/', any nav item that has
 * '/tutorial/' as the first segment in its path should be set as active.
 *
 * @param {string} linkPath
 * @param {?string} currentPath
 *
 * @return {boolean}
 */
export function isActiveNav(linkPath: string, currentPath?: string): boolean {
  if (currentPath === undefined) {
    return false;
  }

  const currentParent = currentPath.split("/")[1];
  const linkParent = linkPath.split("/")[1];

  return linkParent === currentParent ? true : false;
}

/**
 * After the drawer is shown:
 * 1) Hide the scrollbar.
 * 2) Add right padding to avoid a visual "jump" in content.
 * 3) Add `aria-hidden="true"` on selected elements to "trap" where the screen
 *    reader can navigate, like a focus trap.
 */
export function showDrawer(): void {
  const scrollWidth = window.innerWidth - document.body.offsetWidth;

  document.body.style.overflow = "hidden";
  scrollWidth > 0 && (document.body.style.paddingRight = `${scrollWidth}px`);

  [
    "main-header",
    "main-footer",
    "flexport-dev-main",
    "gdpr-banner",
    "portal-toast",
  ].forEach(id => {
    const $el = document.getElementById(id);

    $el && $el.setAttribute("aria-hidden", "true");
  });
}

/**
 * After the drawer is hidden:
 * 1) Reinstate scrolling.
 * 2) Remove right padding.
 * 3) Remove `aria-hidden="true"` from elements hidden from screen readers.
 */
export function hideDrawer(): void {
  document.body.style.overflow = "";
  document.body.style.paddingRight = "";

  [
    "main-header",
    "main-footer",
    "flexport-dev-main",
    "gdpr-banner",
    "portal-toast",
  ].forEach(id => {
    const $el = document.getElementById(id);

    $el && $el.removeAttribute("aria-hidden");
  });
}

/**
 * GA4 event tracking, specifically for clicks on the side nav and TOC items.
 *
 * @param {string} id
 * @param {string} listName
 * @param {string} itemName
 */
export function gtagTrackClick(
  id: string,
  listName: string,
  itemName: string
): void {
  try {
    /* eslint-disable camelcase */
    /* eslint-disable-next-line @typescript-eslint/no-explicit-any */
    (window as any).gtag("event", "select_item", {
      item_list_id: id,
      item_list_name: listName,
      items: [{item_name: itemName}],
    });
    /* eslint-enable camelcase */
  } catch (e) {
    // do nothing.
  }
}

/**
 * Stylised console logging with flexport prepended in blue text background
 * for easier reading/scanning in logs.
 *
 * @param {string[]} args
 */
export function fpLog(...args: string[]): void {
  // eslint-disable-next-line no-console
  console.log("\x1b[44m%s\x1b[0m", "flexport", ...args);
}

/**
 * helper method to check if a component should be visible
 * in the current LOCALE
 * @param {Array<string>} visibleIn
 * @param {string} locale
 * @return {boolean}
 */
export const visibleForLocale = (visibleIn: Array<string>, locale: string) => {
  return !visibleIn || visibleIn.length === 0 || visibleIn.includes(locale);
};

/**
 * Given a slug ('/login'), return the locale specific URL. The input
 * must start with a slash ('/').
 *
 * Ex: if the user is Chinese, localizeSlug('/login') would return
 * https://cn.flexport.com/login
 *
 * This function should be used when linking to the equivalent page on
 * the old PublicRedesignApp (such as linking to the old /careers page);
 * this function DOES NOT need to be used when linking to a page within
 * the Gatsby app.
 *
 * @param {string} slug
 * @param {string} locale
 *
 * @return {string}
 */
export const localizeSlug = (slug: string, locale: string): string => {
  const domain = LOCALE_TO_DOMAIN[locale];
  if (slug.charAt(0) !== "/") {
    throw new Error("Slug must start with '/'");
  }
  return generateURLWithTrailingSlash(`${domain}${slug}`);
};

/**
 * helper method to deepmerge object
 * ref: https://gist.github.com/ahtcx/0cd94e62691f539160b32ecda18af3d6?permalink_comment_id=3889214#gistcomment-3889214
 * deep merge object util method WITHOUT using structuredClone API, as this is not supported
 * in old browser like Safari 15.3, this will cause infinite re-rendering
 * GH-2358 issue to track this.
 *
 * @see https://developer.mozilla.org/en-US/docs/Web/API/StructuredCloneUtils/StructuredClone, re structuredClone API
 *
 * @param {Record<string, any>} obj1
 * @param {Record<string, any>} obj2
 * @return {Record<string, any>}
 */
export const deepMergeObject = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
) => {
  for (const [key, val] of Object.entries(obj1)) {
    if (val !== null && typeof val === `object`) {
      obj2[key] ??= new val.__proto__.constructor();
      deepMergeObject(val, obj2[key]);
    } else {
      obj2[key] = val;
    }
  }
  return obj2;
};
